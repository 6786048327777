/* @include box-shadow(1px,1px,1px,0, #fff, true); */
:root {
  --main-bg-color: $main_bg_color;
  --main-text-color: $main_text_color ;
}

@-webkit-keyframes shine {
  0% {
    background-position: 0;
  }
  60% {
    background-position: 180px;
  }
  100% {
    background-position: 180px;
  }
}

@-moz-keyframes shine {
  0% {
    background-position: 0;
  }
  60% {
    background-position: 180px;
  }
  100% {
    background-position: 180px;
  }
}

@keyframes shine {
  0% {
    background-position: 0;
  }
  60% {
    background-position: 180px;
  }
  100% {
    background-position: 180px;
  }
}

@-webkit-keyframes infiniteMove {
  0% {
    border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
  }
  50% {
    border-radius: 30% 60% 70% 40% / 50% 60% 30% 60%;
  }
  100% {
    border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
  }
}

@-moz-keyframes infiniteMove {
  0% {
    border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
  }
  50% {
    border-radius: 30% 60% 70% 40% / 50% 60% 30% 60%;
  }
  100% {
    border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
  }
}

@keyframes infiniteMove {
  0% {
    border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
  }
  50% {
    border-radius: 30% 60% 70% 40% / 50% 60% 30% 60%;
  }
  100% {
    border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
  }
}

@-webkit-keyframes infiniteMove2 {
  0% {
    border-radius: 60% 30% 70% 40% / 60% 40% 30% 70%;
  }
  50% {
    border-radius: 50% 60% 30% 60% / 30% 60% 70% 40%;
  }
  100% {
    border-radius: 60% 30% 70% 40% / 60% 40% 30% 70%;
  }
}

@-moz-keyframes infiniteMove2 {
  0% {
    border-radius: 60% 30% 70% 40% / 60% 40% 30% 70%;
  }
  50% {
    border-radius: 50% 60% 30% 60% / 30% 60% 70% 40%;
  }
  100% {
    border-radius: 60% 30% 70% 40% / 60% 40% 30% 70%;
  }
}

@keyframes infiniteMove2 {
  0% {
    border-radius: 60% 30% 70% 40% / 60% 40% 30% 70%;
  }
  50% {
    border-radius: 50% 60% 30% 60% / 30% 60% 70% 40%;
  }
  100% {
    border-radius: 60% 30% 70% 40% / 60% 40% 30% 70%;
  }
}

@-webkit-keyframes circle {
  0% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  100% {
    width: 200%;
    height: 200%;
    opacity: 0;
  }
}

@-moz-keyframes circle {
  0% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  100% {
    width: 200%;
    height: 200%;
    opacity: 0;
  }
}

@keyframes circle {
  0% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  100% {
    width: 200%;
    height: 200%;
    opacity: 0;
  }
}

footer {
  position: relative;
  background: #333;
  margin-top: 60px;
}

footer .footer_top {
  padding: 15px  0  35px;
}

footer .container .row {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
     -moz-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

footer .container .row .footer-col {
  color: #fff;
  padding: 0 15px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 20%;
     -moz-box-flex: 0;
      -ms-flex: 0 0 20%;
          flex: 0 0 20%;
}

footer .container .row .footer-col .widgetizedArea {
  color: #fff;
}

footer .container .row .footer-col .widgetizedArea h3, footer .container .row .footer-col .widgetizedArea h4 {
  color: #fff;
  line-height: 1.3;
  white-space: nowrap;
}

footer .container .row .footer-col:first-child {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 30%;
     -moz-box-flex: 0;
      -ms-flex: 0 0 30%;
          flex: 0 0 30%;
}

footer .container .row .footer-col:nth-child(2) {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 20%;
     -moz-box-flex: 0;
      -ms-flex: 0 0 20%;
          flex: 0 0 20%;
}

footer .container .row .footer-col:nth-child(4) {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 30%;
     -moz-box-flex: 0;
      -ms-flex: 0 0 30%;
          flex: 0 0 30%;
}

footer .container .row .footer-col .col-title {
  font-weight: bold;
  font-size: .9rem;
  color: #fff;
  margin-bottom: 15px;
}

footer .container .row .footer-col p {
  font-size: .9rem;
  line-height: 1.2rem;
  margin-top: 0;
  color: #fff;
}

footer .container .row .footer-col a {
  font-size: .9rem;
  color: #fff;
  text-decoration: underline;
  -webkit-transition: color ease .3s;
  -o-transition: color ease .3s;
  -moz-transition: color ease .3s;
  transition: color ease .3s;
}

footer .container .row .footer-col a:hover {
  color: #263c85;
  -webkit-transition: color ease .3s;
  -o-transition: color ease .3s;
  -moz-transition: color ease .3s;
  transition: color ease .3s;
}

footer .container .row .footer-col a.get-access-now {
  background-color: #263c85;
  color: #fff;
  padding: 20px 30px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 3px;
  font-family: 'Avenir Next', system-ui;
  font-weight: bold;
  font-size: .9rem;
  line-height: 1.2rem;
  text-decoration: none;
  white-space: nowrap;
  -webkit-transition: background-color ease .3s;
  -o-transition: background-color ease .3s;
  -moz-transition: background-color ease .3s;
  transition: background-color ease .3s;
}

footer .container .row .footer-col a.get-access-now:hover {
  -webkit-transition: background-color ease .3s;
  -o-transition: background-color ease .3s;
  -moz-transition: background-color ease .3s;
  transition: background-color ease .3s;
  background-color: #263c85;
  border: none;
  color: #fff;
}

footer .container .row .footer-col ul {
  margin: 0;
  padding: 0;
}

footer .container .row .footer-col ul li {
  margin: 4px 0;
}

footer .container .row .footer-col ul li a {
  font-size: .9rem;
  color: #fff;
  text-decoration: none;
}

footer .container .row .footer-col ul li a:hover {
  text-decoration: underline;
  color: #fff;
}

footer .container .row .footer-col ul.social {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
     -moz-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  padding: 0;
  margin: 0;
}

footer .container .row .footer-col ul.social li {
  margin-right: 15px;
}

footer .container .row .footer-col ul.social li a {
  -webkit-transform: all ease .3s;
     -moz-transform: all ease .3s;
      -ms-transform: all ease .3s;
          transform: all ease .3s;
  background-color: #E65B00;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  padding: 10px;
  border-radius: 50%;
}

footer .container .row .footer-col ul.social li a:hover {
  -webkit-transform: all ease .3s;
     -moz-transform: all ease .3s;
      -ms-transform: all ease .3s;
          transform: all ease .3s;
  opacity: .7;
}

footer .container .row .footer-col img.footer_logo {
  -webkit-filter: invert(1);
          filter: invert(1);
  margin-bottom: 10px;
}

footer .footer_bottom {
  padding: 9px 0;
  background-color: #3e3235;
}

footer .footer_bottom .row {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

footer .footer_bottom p {
  font-size: .8rem;
  margin: 0;
  color: #fff;
}

.sticky_info {
  position: fixed;
  bottom: 80px;
  left: 15px;
  z-index: 99999;
}

.sticky_info .item {
  position: relative;
  margin-top: 10px;
  -webkit-transition: all .5s ease-in-out;
  -o-transition: all .5s ease-in-out;
  -moz-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;
  z-index: 999;
}

.sticky_info .item a {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  -webkit-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4);
          box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4);
  font-size: 28px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  line-height: 50px;
}

.sticky_info .item a svg {
  fill: #fff;
}

.sticky_info .item.phone a {
  background: #337ab7;
}

.sticky_info .item.telegram a {
  background: #27A7E5;
}

.sticky_info .item.whatsapp a {
  background: #4dc247;
}

.sticky_info .item.instagram a {
  background-color: #fd3a67;
}

.sticky_info .item .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  line-height: normal;
  font-size: 15px;
  top: 13px;
  left: 105%;
  height: auto;
}

.sticky_info .item:hover .tooltiptext {
  visibility: visible;
}

.sticky_info .item:hover a {
  -webkit-transform: rotate(45deg);
     -moz-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
          transform: rotate(45deg);
}

#olb_search_content {
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  -webkit-transform: translateX(100vw);
     -moz-transform: translateX(100vw);
      -ms-transform: translateX(100vw);
          transform: translateX(100vw);
  -webkit-transition: -webkit-transform ease .3s;
  transition: -webkit-transform ease .3s;
  -o-transition: transform ease .3s;
  -moz-transition: transform ease .3s, -moz-transform ease .3s;
  transition: transform ease .3s;
  transition: transform ease .3s, -webkit-transform ease .3s, -moz-transform ease .3s;
}

#olb_search_content.active {
  z-index: 999;
  -webkit-transform: translateX(0);
     -moz-transform: translateX(0);
      -ms-transform: translateX(0);
          transform: translateX(0);
  -webkit-transition: -webkit-transform ease .3s;
  transition: -webkit-transform ease .3s;
  -o-transition: transform ease .3s;
  -moz-transition: transform ease .3s, -moz-transform ease .3s;
  transition: transform ease .3s;
  transition: transform ease .3s, -webkit-transform ease .3s, -moz-transform ease .3s;
  overflow: visible !important;
  width: 100% !important;
  height: 100% !important;
}

#olb_search_content .inner {
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: relative;
}

#olb_search_content .inner .search_title {
  font-size: 36px;
  font-family: "Titillium Web", sans-serif;
  font-weight: 700;
  color: #000;
  margin-bottom: 30px;
}

#olb_search_content .inner #olb_search_close {
  position: absolute;
  top: 30px;
  right: 30px;
  border: 1px solid #000;
  color: #000;
  padding: 10px;
  font-size: 36px;
  line-height: 36px;
  width: 57px;
  cursor: pointer;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 3px;
}

#olb_search_content .inner form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}

#olb_search_content .inner form input {
  min-width: 320px;
  border-radius: 3px 0 0 3px;
  border: 1px solid #000;
  padding: 15px;
  font-family: "Titillium Web", sans-serif;
}

#olb_search_content .inner form input:focus {
  border: none;
}

#olb_search_content .inner form button[type="submit"] {
  min-width: 64px;
  border-radius: 0px 3px 3px 0;
  color: #fff;
  background-color: #C5212F;
}

@media (max-width: 1024px) {
  footer .footer_top {
    overflow: hidden;
  }
}

@media (max-width: 960px) {
  footer .footer_top {
    overflow: hidden;
  }
  footer .container .row .col-5-12 {
    margin-bottom: 50px;
    -webkit-box-ordinal-group: 4;
    -webkit-order: 3;
       -moz-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 33%;
            flex: 0 0 33%;
  }
  footer .container .row .col-5-12:first-child {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
       -moz-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
  }
  footer .container .row .col-5-12:last-child {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
       -moz-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }
  footer .container .row .col-5-12 p {
    font-size: 1rem;
    line-height: 2;
  }
  footer .container .row .col-5-12 .col-title {
    font-size: 1rem;
    line-height: 2;
  }
  footer .container .row .col-5-12 ul li a {
    font-size: 1rem;
    line-height: 1;
  }
  footer .container .row .col-5-12 a.get-access-now {
    font-size: 1rem;
    line-height: 1;
  }
  footer .container .row .col-5-12:nth-child(2) {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 33%;
            flex: 0 0 33%;
  }
  .footer_bottom {
    padding: 9px 0;
    background-color: #fff;
  }
  .footer_bottom .row {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .footer_bottom p {
    font-size: 1rem !important;
  }
}

@media (max-width: 768px) {
  footer .container .row .col-5-12 p {
    font-size: 1rem;
    line-height: 2;
  }
  footer .container .row .col-5-12 .col-title {
    font-size: 1.1rem;
    line-height: 2;
  }
  footer .container .row .col-5-12 ul li a {
    font-size: 1rem;
    line-height: 1;
  }
  footer .container .row .col-5-12 a.get-access-now {
    font-size: 1rem;
    line-height: 1;
  }
  footer .container .row .footer-col {
    -webkit-box-flex: 0 !important;
    -webkit-flex: 0 0 50% !important;
       -moz-box-flex: 0 !important;
        -ms-flex: 0 0 50% !important;
            flex: 0 0 50% !important;
  }
  footer .container .row .footer-col .widgetizedArea h3, footer .container .row .footer-col .widgetizedArea h4 {
    font-size: 18px;
  }
  .sticky_info {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    bottom: 0;
    left: 0;
    width: 100%;
  }
  .sticky_info .item {
    width: 25%;
  }
  .sticky_info .item a {
    height: 50px;
    width: 100%;
    border-radius: 0%;
    -webkit-box-shadow: none;
            box-shadow: none;
    font-size: 28px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
  }
  .sticky_info .item a svg {
    fill: #fff;
  }
  .sticky_info .item.phone a {
    background: #337ab7;
  }
  .sticky_info .item.telegram a {
    background: #27A7E5;
  }
  .sticky_info .item.whatsapp a {
    background: #4dc247;
  }
  .sticky_info .item.instagram a {
    background-color: #fd3a67;
  }
  .sticky_info .item .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    line-height: normal;
    font-size: 15px;
    top: 13px;
    left: 105%;
    height: auto;
  }
  .sticky_info .item:hover .tooltiptext {
    visibility: visible;
  }
  .sticky_info .item:hover a {
    -webkit-transform: rotate(45deg);
       -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
            transform: rotate(45deg);
  }
}

@media (max-width: 650px) {
  footer .footer_top {
    padding: 40px 0;
  }
  footer .container .row .col-5-12 p {
    font-size: 1.05rem;
    line-height: 1.7;
  }
  footer .container .row .col-5-12 .col-title {
    font-size: 1.2rem;
  }
  footer .container .row .col-5-12 ul li a {
    font-size: 1.05rem;
  }
  footer .container .row .col-5-12 a.get-access-now {
    font-size: 1rem;
    line-height: 1;
    padding: 22px 30px;
  }
  footer .container .row {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
       -moz-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
  }
  footer .container .row .col-5-12 {
    padding: 0 15px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    margin-bottom: 60px;
  }
  footer .container .row .col-5-12:first-child {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
  }
  footer .container .row .col-5-12:nth-child(2) {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
  }
  footer .container .row .col-5-12:last-child {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
  }
}

@media (max-width: 450px) {
  footer .container .row .col-5-12 {
    padding: 0 15px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    margin-bottom: 60px;
  }
  footer .container .row .col-5-12:first-child {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    margin-bottom: 60px;
  }
  footer .container .row .col-5-12:nth-last-child(2) {
    margin-bottom: 0;
  }
  footer .container .row .col-5-12:last-child {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    margin-bottom: 60px;
  }
  .footer_bottom {
    padding: 15px 0;
    background-color: #fff;
  }
  .footer_bottom .row {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .footer_bottom p {
    font-size: 1rem !important;
  }
}
